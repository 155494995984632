import {CommonModel} from "@/model/CommonModel";
import {getPageList, getOne, getList} from "@/api/erp/OfficialWebNewsApi";

/**
 * 官网新闻model
 */
class OfficialWebNewsModel {
  // 获取详情
  static async getOne(id) {
    let [data] = await getOne(id);
    return data.data;
  }

  // 获取分页列表
  static async getPageList(page, size, sort, document) {
    let [data] = await getPageList(page, size, sort, document);
    return CommonModel.generateListMongo(data.data);
  }

  // 获取不分页列表
  static async getList( document) {
    let [data] = await getList(document);
    return data.data;
  }

  //
}

export {OfficialWebNewsModel}
