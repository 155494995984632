<template>
  <div class="page-container">
    <!--新闻头部-->
    <div class="news-header"  v-if="newsManage.bannerManage.type==='video'||newsManage.bannerManage.type==='img'">
      <!--视频模式-->
      <div class="video-model" v-if="newsManage.bannerManage.type==='video'">
        <video id="v2" autoplay="autoplay" loop="loop" preload="" muted="muted"
               x-webkit-airplay="true" airplay="allow" webkit-playsinline="true" playsinline="true"
               src="http://resouce.cdzyhd.com/84b0b1ec-a2dd-464b-88fe-1c32667c6730.mp4" draggable="true"
               class="video2 video"></video>
      </div>
      <!--图片模式-->
      <div class="image-model" v-if="newsManage.bannerManage.type==='img'"></div>
    </div>
    <div class="bg-world" v-if="newsManage.bannerManage.type==='world'">
      <div class="world">
        <div class="world-bg"
             style="background-image: url('http://resouce.cdzyhd.com/a03438e6-8911-4b8d-91e2-efd0669fd557.jpg'); transform: scale3d(1, 1, 1);"></div>
        <div class="world-globe" style="transform: scale3d(1, 1, 1);">
          <div class="world-globe-pole" style="display: block;"></div>
          <div class="world-globe-doms-container">
          </div>
          <div class="world-globe-halo"
               style="background-image: url('http://resouce.cdzyhd.com/b07a37f9-4993-4a2a-8a9b-c3fa30ababb9.png'); display: block;"></div>
        </div>
        <div class="text">
          <div>新闻中心</div>
          <div class="english">News Center</div>
        </div>
      </div>
    </div>
    <!--  新闻详情  -->
    <div class="detail-container" v-if="news.title">
      <!-- 头部 -->
      <div class="header-box">
        <h1 class="title">{{ news.title }}</h1>
        <div class="date">发布时间：{{ date_format(news.createTime, "yyyy-MM-dd HH:mm") }}</div>
      </div>
      <!-- 详情 -->
      <div class="content-box news-content-box" v-html="news.content">
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.page-container {
  background-color: #f2f2f2;
}

.bg-world{
  .world .text{
    text-align: center;
    font-size: 70px;
    position: absolute;
    width: 300px;
    height: 250px;
    bottom: 100px;
    left: 15%;
    color: #fff;
    margin:0 auto;
    font-weight: bold;
    .english{
      font-size: 44px;
      margin-top: 10px;
    }
  }
}

// 顶部
.news-header {
  margin-bottom: 50px;

  .video-model {
    width: 100%;
    position: relative;
    height: 600px;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 600px;
      object-fit: fill;
    }
  }

  // 图片模式
  .image-model {
    z-index: 0;
    width: 100%;
    height: 600px;
    color: transparent;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 1;
    -webkit-backface-visibility: hidden;
  }
}

.detail-container {
  width: 1000px;
  margin: 0 auto;
  padding: 30px 50px;
  background-color: #fff;

  .header-box {
    text-align: center;

    .title {
      font-size: 24px;
      font-weight: 700;
      color: #333333;
      padding-top: 60px;
      padding-bottom: 40px;
      margin-top: 0px
    }

    .date {
      font-size: 14px;
      font-weight: 400;
      color: #6c6c6c;
      padding-bottom: 40px;
    }
  }

  .content-box {

  }
}
</style>
<style>
#header-container {
  background-color: rgba(0, 0, 0, 0.4);
}

#header-container .navs a {
  color: #fff !important;
  opacity: 0.6;
}

#header-container .navs a:hover {
  opacity: 1;
}

#header-container img.black {
  display: none;
}

#header-container .product-navs .list {
  display: none;
}
</style>
<script>
import {OfficialWebNewsModel} from "../../model/erp/OfficialWebNewsModel";
import {ConfigModel} from "../../model/erp/ConfigModel";
import {date_format} from "../../utils/common";
import $ from "jquery";
import 'objectFitPolyfill';
import {msg_err} from "../../utils/ele_component";

export default {
  name: "newsView",
  data() {
    return {
      date_format: date_format,
      // 配置
      newsManage: {
        bannerManage: {}
      },
      newsId: this.$route.query.id,
      news: {}
    }
  },
  async mounted() {
    // 获取新闻页配置
    let newsManage = await ConfigModel.getNewsConfig()
    this.$set(this, "newsManage", newsManage);
    // 开始渲染
    this.$nextTick(() => {
      if(newsManage.bannerManage.type==='world'){
        // 绘制地球
        let css1 = document.createElement('link');
        css1.rel = 'stylesheet';
        css1.type = "text/css"
        css1.href = 'news/world.css';
        document.body.append(css1);
        css1.addEventListener('load', e => {
          let script1 = document.createElement('script');
          script1.id = 'dataGui';
          script1.src = 'news/dat.gui.min.js';
          document.body.append(script1);
          script1.addEventListener('load', e => {
            let script2 = document.createElement('script');
            script2.id = 'globe';
            script2.src = 'news/css_globe_PerspectiveTransform.js';
            document.body.append(script2);
            script2.addEventListener('load', e => {
              let script3 = document.createElement('script');
              script3.id = 'worldScript';
              script3.src = 'news/script.js';
              document.body.append(script3);
              script3.addEventListener('load', e => {
                $("div.dg").hide()
              });
            })
          })
        })
      }else{
        this.drawBanner();
      }
      // 监听窗口大小改变
      $(window).resize(() => {
        if (this.bannerManage.type === "video") {
          // 视频背景图改变
          let clientWidth = document.body.clientWidth
          $(".video-model video").css("width", clientWidth + "px")
          $(".video-model video").css("marginLeft", -clientWidth / 2 + "px")
        }
      });
      // 解决ie11 视频banner不全屏问题 因为object-fill不被支持
      let videoElement = document.querySelector('#video');
      objectFitPolyfill(videoElement);
    })
    if (!this.newsId) {
      window.location.href = "/"
    }
    // 获取新闻详情
    let news = await OfficialWebNewsModel.getOne(this.newsId).catch(err => {
      msg_err("未找到该新闻!");
    })
    if (news) {
      this.news = news;
    }
  },
  methods: {
    // 渲染banner
    drawBanner() {
      if (this.newsManage.bannerManage.type === "video") {
        $(".video-model video").attr("src", this.newsManage.bannerManage.banner_pc_video)
      } else {
        $(".image-model").css("background-image", `url(${this.newsManage.bannerManage.banner_pc_img})`)
      }
    },
  }
}
</script>

