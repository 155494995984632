import {request_async} from "@/utils/requestAsync";
import {API_URL_ERP} from "@/model/ConfigModel";

// 获取列表-分页
export async function getPageList(page, size, sort, data) {
    return request_async(API_URL_ERP + `/v1/officialWeb/news/pageList?page=${page}&size=${size}&sort=${sort}`, "post_body", data);
}

// 获取列表-不分页
export async function getList(data) {
    return request_async(API_URL_ERP + `/v1/officialWeb/news/list`, "post_body", data);
}

// 获取一个
export async function getOne(id) {
    return request_async(API_URL_ERP + `/v1/officialWeb/news`, "get", {
        officialWebNewsId: id
    });
}
