// 常用model
class CommonModel {

  // 对获取到的对象列表进行格式化-Mongo
  static generateListMongo(data) {
    let list = data.content;
    // 生成分页数据
    delete data.content;
    let page_temp = data;
    page_temp.number += 1;
    // 生成列表
    return [list, page_temp];
  }
}

export {CommonModel}
